<template>
	<div>
		<details-page-gen :options="options" />
	</div>
</template>

<script>
import DetailsPageGen from './DetailsPageGen.vue'

const config = {
	options: {
		resource: 'user-groups',
		display: ['id', 'name', 'users', 'createdAt', 'updatedAt']
	}
}

export default {
	name: 'UserGroupsDetails',
	components: { DetailsPageGen },
	data: () => config
}
</script>
