<template>
	<div>
		<list-page-gen :options="options" />
	</div>
</template>

<script>
import ListPageGen from './ListPageGen.vue'

const config = {
	options: {
		resource: 'user-groups',
		display: [{ key: 'id' }, { key: 'name' }, { key: 'users' }]
	}
}

export default {
	name: 'UserGroupsList',
	components: { ListPageGen },
	data: () => config
}
</script>
